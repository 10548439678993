import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["box", "ignore"];

    close(event) {
        if (event.target instanceof Element) {
            let clickInsidedIgnore = false;
            if (this.hasIgnoreTarget) {
                this.ignoreTargets.forEach((element) => {
                    if (element.contains(event.target)) {
                        clickInsidedIgnore = true;
                    }
                });
            }
            if (!this.boxTarget.contains(event.target) && !clickInsidedIgnore) {
                event.preventDefault();
                this.dispatch("registered");
                removeEventListener("click", this.closeHandler);
            }
        }
    }

    toggle() {
        if (this.closeHandler) {
            removeEventListener("click", this.closeHandler);
        } else {
            this.closeHandler = this.close.bind(this);
            setTimeout(() => {
                addEventListener("click", this.closeHandler);
            }, 0);
        }
    }

    boxTargetConnected(element) {
        if (element.hasAttribute(`data-${this.identifier}-box-is-open`)) {
            this.closeHandler = this.close.bind(this);
            addEventListener("click", this.closeHandler);
        }
    }

    boxTargetDisconnected() {
        removeEventListener("click", this.closeHandler);
    }
}
