import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["shortTeaser", "longTeaser"];

    showMore() {
        this.shortTeaserTarget.classList.add("hidden");

        this.longTeaserTarget.classList.remove("invisible");
        this.longTeaserTarget.style.maxHeight = this.shortTeaserTarget.clientHeight + "px";

        this.longTeaserTarget.classList.add("read-more-transition");

        this.longTeaserTarget.style.opacity = 1;
        this.longTeaserTarget.style.maxHeight = "200vh";
    }
}
