import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["scrollMe", "left", "right"];
    static values = { showLeft: String, showRight: String };

    declare readonly scrollMeTarget: HTMLElement;
    declare readonly leftTarget: HTMLElement;
    declare readonly rightTarget: HTMLElement;
    declare readonly showLeftValue: string;
    declare readonly showRightValue: string;

    ticking: boolean = false;

    connect() {
        this.hideLeftOrRight();
    }

    hideLeftOrRight() {
        const scrollMe = this.scrollMeTarget;
        const showLeftClassAttributeName = `data-${this.identifier}-show-class`;
        const showRightClassAttributeName = `data-${this.identifier}-show-class`;
        const showLeftClass = this.leftTarget.getAttribute(showLeftClassAttributeName) ?? "";
        const showRightClass = this.rightTarget.getAttribute(showRightClassAttributeName) ?? "";

        if (scrollMe.scrollLeft === 0) {
            this.leftTarget.classList.remove(showLeftClass);
        } else if (scrollMe.scrollLeft !== 0 && !this.leftTarget.classList.contains(showLeftClass)) {
            this.leftTarget.classList.add(showLeftClass);
        }

        const pos = scrollMe.scrollLeft + scrollMe.offsetWidth;
        if (scrollMe.scrollWidth === pos) {
            this.rightTarget.classList.remove(showRightClass);
        } else if (scrollMe.scrollWidth !== pos && !this.rightTarget.classList.contains(showRightClass)) {
            this.rightTarget.classList.add(showRightClass);
        }
    }

    addScrollEventListener(target: HTMLElement, callback: (scrollMeTarget: HTMLElement) => void) {
        const that = this;
        const fn = callback.bind(that);
        target.addEventListener("scroll", () => {
            if (!that.ticking) {
                window.requestAnimationFrame(() => {
                    fn();
                    that.ticking = false;
                });
            }

            that.ticking = true;
        });
    }

    scrollMeTargetConnected(target: HTMLElement) {
        this.addScrollEventListener(target, this.hideLeftOrRight);
    }

    toLeft() {
        const scrollMe = this.scrollMeTarget;
        const delta = scrollMe.offsetWidth * 0.7;
        let newX = scrollMe.scrollLeft - delta;
        if (newX < delta) {
            newX = 0;
        }

        scrollMe.scrollTo({ left: newX, top: 0, behavior: "smooth" });
    }

    toRight() {
        const scrollMe = this.scrollMeTarget;
        const delta = scrollMe.offsetWidth * 0.75;
        const spaceLeft = scrollMe.scrollWidth - (scrollMe.scrollLeft + scrollMe.offsetWidth);
        let newX = scrollMe.scrollLeft + delta;
        if (spaceLeft <= delta) {
            newX = scrollMe.scrollWidth;
        }

        scrollMe.scrollTo({ left: newX, top: 0, behavior: "smooth" });
    }
}
