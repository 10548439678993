import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["root"];
    static values = {
        offset: {
            type: Number,
            default: 0,
        },
        scollBehavior: {
            type: String,
            default: "smooth",
        },
    };

    connect() {
        const fragment = location.hash;

        if (fragment === "") {
            return;
        }

        if (!this.hasRootTarget) {
            return;
        }

        const root = this.rootTarget;

        const toElement = document.querySelector(fragment);
        if (!toElement) {
            console.warn(`${this.identifier}: couldn't find element ${fragment} to scrollTo`);
            return;
        }
        const compBounds = toElement.getBoundingClientRect();
        const newTop = compBounds.top + window.scrollY + this.offsetValue;
        root.scrollTo({
            behavior: this.scollBehaviorValue,
            left: 0,
            top: newTop,
        });
    }
}
