import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["mask", "data", "scrollMeWithMap"];

    goTo(event) {
        let screenWidth = window.innerWidth;
        let showMapBreakpoint = 1200;
        let scrollMe = window;
        let toElementTop = 0;
        if (screenWidth >= showMapBreakpoint) {
            scrollMe = this.scrollMeWithMapTarget;
            toElementTop = scrollMe.getBoundingClientRect().top;
        }

        const toElement =
            event.params.elementid !== ""
                ? document.querySelector(event.params.elementid)
                : document.getElementById("content-start");
        this.toggle();
        scrollMe.scrollTo({
            behavior: "auto",
            left: 0,
            top: toElement.getBoundingClientRect().top - toElementTop - 30,
        });
    }

    toggle() {
        document.body.classList.toggle("overflow-hidden");

        this.maskTarget.classList.toggle("invisible");
        this.maskTarget.classList.toggle("pointer-events-none");
        this.maskTarget.classList.toggle("bg-transparent");

        this.dataTarget.classList.toggle("w-[0_!important]");
        this.dataTarget.classList.toggle("opacity-0");
    }

    closeOnMaskClick(event) {
        if (event.target === this.maskTarget) {
            this.toggle();
        }
    }
}
