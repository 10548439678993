import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    load(event) {
        if (!Turbo) {
            console.warn("Turbo object missing");
            return;
        }

        const { url } = event.params;
        fetch(url, {
            headers: {
                Accept: "text/vnd.turbo-stream.html",
            },
        })
            .then((rp) => {
                if (rp.ok) {
                    return rp.text();
                }
                throw rp.status;
            })
            .then((html) => {
                Turbo.renderStreamMessage(html);
            })
            .catch((error) => console.warn(error));
    }
}
