import { Controller } from "@hotwired/stimulus";
import { Swiper, Pagination, Navigation } from "swiper";
import "swiper/css/bundle";
import "swiper/css/pagination";

export default class extends Controller {
    static targets = ["swiper"];
    static values = {
        options: Object,
    };

    connect() {
        window.requestAnimationFrame(() => {
            for (const t of this.swiperTargets) {
                const programId = t.getAttribute("data-image-swipers-program-id");
                new Swiper(t, {
                    modules: [Navigation, Pagination],
                    ...this.opts(programId),
                    ...this.optionsValue,
                });
            }
        });
    }

    opts(programId) {
        return {
            slidesPerView: 1,
            navigation: {
                hiddenClass: ".opacity-0",
                nextEl: `.next-${programId}`,
                prevEl: `.prev-${programId}`,
            },
            pagination: {
                el: `.swiper-pagination-${programId}`,
                dynamicBullets: true,
                clickable: true,
                dynamicMainBullets: 3,
            },
        };
    }
}
