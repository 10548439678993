import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["overlay", "about", "notIncluded", "fees", "host", "calendar"];

    openModal(target) {
        this.overlayTarget.classList.remove("opacity-0", "pointer-events-none");
        document.body.classList.add("overflow-hidden");

        target.classList.remove("opacity-0", "pointer-events-none");
    }

    openAboutModal() {
        this.openModal(this.aboutTarget);
    }

    openNotIncludedModal() {
        this.openModal(this.notIncludedTarget);
    }

    openFeesModal() {
        this.openModal(this.feesTarget);

        this.application.getControllerForElementAndIdentifier(this.calendarTarget, "calendar").loadCalendar();
    }

    openHostModal() {
        this.openModal(this.hostTarget);
    }

    closeModal() {
        this.removeModalElement(this.aboutTarget);
        this.removeModalElement(this.notIncludedTarget);
        this.removeModalElement(this.feesTarget);
        this.removeModalElement(this.hostTarget);

        this.overlayTarget.classList.add("opacity-0", "pointer-events-none");
        document.body.classList.remove("overflow-hidden");
    }

    removeModalElement(target) {
        target.classList.add("opacity-0", "pointer-events-none");
    }
}
