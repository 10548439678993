import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["viewPort", "navbar", "misc"];

    state = "start";

    navbarTargetConnected() {
        let viewPort = document;
        if (this.hasViewPortTarget) {
            viewPort = this.viewPortTarget;
        }
        viewPort.addEventListener("scroll", () => {
            const pos = viewPort.scrollTop || window.scrollY;
            if (this.prevPos) {
                if (this.prevPos < pos && pos - 10 >= 0) {
                    //user scrolls down
                    // "pos - 10 >= 0" -> when scrolling to the top on a mobile phone the view is bouncing back a little bit,
                    // which is then recognized as scrolling down again, so that the navbar is hidden, to prevent this behavior this small buffer is added
                    this.prevPos = pos;
                    if (this.state != "hidden") {
                        this.state = "hidden";
                        this.navbarTarget.classList.add("transition-transform", "duration-300", "translate-y-full");
                        for (const e of this.miscTargets) {
                            e.classList.remove("-translate-y-[65px]");
                            e.classList.add("transition-transform", "duration-300", "translate-y-[65px]");
                        }
                    }
                } else {
                    this.prevPos = pos;
                    if (this.state != "shown") {
                        this.state = "shown";
                        this.navbarTarget.classList.remove("translate-y-full");
                        this.navbarTarget.classList.add("transition-transform", "duration-300");
                        for (const e of this.miscTargets) {
                            e.classList.remove("translate-y-[65px]");
                            e.classList.add("transition-transform", "duration-300");
                        }
                    }
                }
            } else {
                this.prevPos = pos;
            }
        });
    }
}
