import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    isResetted = {};

    reset(event) {
        const { target: targetId } = event.params;
        if (this.isResetted[targetId]) {
            return;
        }
        const actionAttribute = `data-${this.identifier}-${targetId}-action`;
        const targetsSelector = `[${actionAttribute}]`;
        const targets = this.element.querySelectorAll(targetsSelector);
        for (const target of targets) {
            const action = target.getAttribute(actionAttribute);
            if (action === "hide") {
                target.setAttribute("hidden", "");
            } else if (action === "show") {
                target.removeAttribute("hidden");
            } else if (action === "remove") {
                target.remove();
            } else if (action === "replace-class") {
                const oldAttribute = `data-${this.identifier}-${targetId}-replace-class-old`;
                const oldClass = target.getAttribute(oldAttribute);
                const newAttribute = `data-${this.identifier}-${targetId}-replace-class-new`;
                const newClass = target.getAttribute(newAttribute);
                target.classList.remove(oldClass);
                target.classList.add(newClass);
            }
            this.isResetted[targetId] = true;
        }
    }
}
