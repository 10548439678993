import { Controller } from "@hotwired/stimulus";

enum Sizes {
    Xxs = 375,
    Xs = 475,
    Ssm = 600,
    Sm = 768,
    Md = 1024,
    Lmd = 1200,
    Lg = 1440,
}

export default class extends Controller {

    targetAttribute: string | null = null

    declare readonly breakpointValue: keyof typeof Sizes;
    declare readonly hasBreakpointValue: string;
    declare readonly pixelValue: number;
    declare readonly hasPixelValue: string;

    static values = {
        breakpoint: String,
        pixel: Number,
    }

    connect(): void {
        window.addEventListener('resize', this.resize)
        this.targetAttribute = this.element.getAttribute("target")
        this.resize()
    }

    disconnect(): void {
        window.removeEventListener('resize', this.resize)
    }

    resize = () => {
        if (this.hasPixelValue) {
            this.checkDataPoints(this.pixelValue)
            return
        }
        if (this.hasBreakpointValue) {
            this.checkDataPoints(Sizes[this.breakpointValue])
        }
    }

    checkDataPoints = (value: number) => {
        if (window.outerWidth > value) {
            this.element.setAttribute("target", "_blank")
            return
        }
        if (this.targetAttribute) {
            this.element.setAttribute("target", this.targetAttribute)
            return
        }
        this.element.removeAttribute("target")
    }
}