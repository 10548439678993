export function setCookie(name, value, attributes = {}) {
    if (typeof document === "undefined") {
        return;
    }

    if (typeof attributes.expires === "number") {
        attributes.expires = new Date(Date.now() + attributes.expires * 864e5);
    }
    if (attributes.expires) {
        attributes.expires = attributes.expires.toUTCString();
    }

    name = encodeURIComponent(name)
        .replace(/%(2[346B]|5E|60|7C)/g, decodeURIComponent)
        .replace(/[()]/g, escape);

    var stringifiedAttributes = "";
    for (var attributeName in attributes) {
        if (!attributes[attributeName]) {
            continue;
        }

        stringifiedAttributes += "; " + attributeName;

        if (attributes[attributeName] === true) {
            continue;
        }

        stringifiedAttributes += "=" + attributes[attributeName].split(";")[0];
    }

    return (document.cookie = name + "=" + value + stringifiedAttributes);
}

export function getCookie(name) {
    if (typeof document === "undefined" || (arguments.length && !name)) {
        return;
    }

    var cookies = document.cookie ? document.cookie.split("; ") : [];
    for (var i = 0; i < cookies.length; i++) {
        var parts = cookies[i].split("=");
        var value = parts.slice(1).join("=");
        var foundName = decodeURIComponent(parts[0]);

        if (name === foundName) {
            return [value, true];
        }
    }

    return ["", false];
}

export function getCookies() {
    var cookies = document.cookie ? document.cookie.split("; ") : [];
    const cookiesList = [];

    // Iterate through the cookie strings
    for (let i = 0; i < cookies.length; i++) {
        var parts = cookies[i].split("=");
        var value = parts.slice(1).join("=");
        var key = decodeURIComponent(parts[0]);

        // Check if the cookie has a key and a value
        if (parts.length >= 2) {
            cookiesList.push({ key, value });
        }
    }

    return cookiesList;
}
