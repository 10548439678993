import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    inProgressEventListener: EventListener;
    successEventListener: EventListener;
    errorEventListener: EventListener;
    targetIds: Set<string>;
    delayedAction: Record<string, number> = {};

    declare readonly targetIdsValue: string;
    declare readonly hasTargetIdsValue: boolean;
    declare readonly delayValue: number;
    declare readonly elementTargets: HTMLElement[];
    static values = {
        targetIds: String,
        delay: {
            type: Number,
            default: 0,
        },
    };
    static targets = ["element"];

    initialize(): void {
        this.targetIds = new Set();
        if (this.hasTargetIdsValue) {
            for (const id of this.targetIdsValue.split(",")) {
                this.targetIds.add(id);
            }
        } else {
            const closestTurboFrame = this.element.closest("turbo-frame");
            if (closestTurboFrame) {
                this.targetIds.add(closestTurboFrame.id);
            }
        }
    }

    hideElementAfter(e: HTMLElement): EventListener {
        return () => {
            e.setAttribute("hidden", "");
        };
    }

    showInProgressElements(targetId: string): () => void {
        return () => {
            if (!this.delayedAction[targetId]) {
                return;
            }
            for (const e of this.elementTargets) {
                const showStateDataAttrName = `data-${this.identifier}-show-on`;
                const showStatesString = e.getAttribute(showStateDataAttrName);
                if (showStatesString) {
                    const states = showStatesString.split(",").map((e) => e.trim());
                    if (states.includes("inProgress")) {
                        e.removeAttribute("hidden");
                    } else {
                        e.setAttribute("hidden", "");
                    }
                } else {
                    e.setAttribute("hidden", "");
                }
            }
            delete this.delayedAction[targetId];
        };
    }

    shouldProcessEvent(event: Event): string {
        if (!event.target || !(event.target instanceof Element)) {
            return "";
        }
        let id = event.target.id;
        if (event.target.tagName === "FORM" && id === "") {
            id = this.element.closest("turbo-frame")?.id ?? "";
        }
        if (!this.targetIds.has(id)) {
            return "";
        }

        return id;
    }

    inProgress(event: Event): void {
        const id = this.shouldProcessEvent(event);
        if (id === "") {
            return;
        }
        const showElements = this.showInProgressElements(id);
        this.delayedAction[id] = setTimeout(showElements.bind(this), this.delayValue);
    }

    show(status: string): void {
        for (const e of this.elementTargets) {
            const stateDataAttrName = `data-${this.identifier}-show-on`;
            const statesString = e.getAttribute(stateDataAttrName);
            if (!statesString) {
                e.setAttribute("hidden", "");
                continue;
            }
            const hideAfterDataAttrName = `data-${this.identifier}-hide-again-after`;
            const hideAfter = e.getAttribute(hideAfterDataAttrName);
            if (hideAfter) {
                setTimeout(this.hideElementAfter(e), parseInt(hideAfter) * 1000);
            }
            const states = statesString.split(",").map((e) => e.trim());
            if (states.includes(status)) {
                e.removeAttribute("hidden");
            } else {
                e.setAttribute("hidden", "");
            }
        }
    }

    success(event: Event): void {
        const id = this.shouldProcessEvent(event);
        if (id === "") {
            return;
        }

        clearTimeout(this.delayedAction[id]);
        delete this.delayedAction[id];

        this.show("success");
    }

    error(event: Event): void {
        const id = this.shouldProcessEvent(event);
        if (id === "") {
            return;
        }

        clearTimeout(this.delayedAction[id]);
        delete this.delayedAction[id];

        this.show("error");
    }

    connect(): void {
        this.inProgressEventListener = this.inProgress.bind(this);
        this.successEventListener = this.success.bind(this);
        this.errorEventListener = this.error.bind(this);
        addEventListener("turbo:before-fetch-request", this.inProgressEventListener);
        addEventListener("turbo:before-fetch-response", this.successEventListener);
        addEventListener("turbo:fetch-request-error", this.errorEventListener);
    }

    disconnect(): void {
        removeEventListener("turbo:click", this.inProgressEventListener);
        removeEventListener("turbo:click", this.successEventListener);
        removeEventListener("turbo:click", this.errorEventListener);
    }
}
