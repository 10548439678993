import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["languageButton", "currencyButton", "panel", "flag", "sign", "language", "currency"];

    initialize() {
        document.body.addEventListener(
            "click",
            (event) => {
                if (
                    this.hasLanguageButtonTarget &&
                    event.target != this.languageButtonTarget &&
                    event.target != this.panelTarget &&
                    event.target != this.flagTarget &&
                    event.target != this.languageTarget
                ) {
                    this.panelTarget.classList.add("scale-0");
                    this.panelTarget.classList.add("-translate-x-[50%]");
                    this.panelTarget.classList.add("-translate-y-[50%]");
                    this.panelTarget.classList.add("opacity-0");
                }
            },
            { passive: true }
        );

        document.body.addEventListener(
            "click",
            (event) => {
                if (
                    this.hasCurrencyButtonTarget &&
                    event.target != this.currencyButtonTarget &&
                    event.target != this.panelTarget &&
                    event.target != this.signTarget &&
                    event.target != this.currencyTarget
                ) {
                    this.panelTarget.classList.add("scale-0");
                    this.panelTarget.classList.add("-translate-x-[50%]");
                    this.panelTarget.classList.add("-translate-y-[50%]");
                    this.panelTarget.classList.add("opacity-0");
                }
            },
            { passive: true }
        );
    }

    toggleDropdown() {
        this.panelTarget.classList.remove("invisible");
        this.panelTarget.classList.toggle("scale-0");
        this.panelTarget.classList.toggle("-translate-x-[50%]");
        this.panelTarget.classList.toggle("-translate-y-[50%]");
        this.panelTarget.classList.toggle("opacity-0");
    }

    changeCurrency(event) {
        const date = new Date();
        date.setMonth(date.getMonth() + 2);
        document.cookie = `currency=${event.params.currency}; expires=${date.toUTCString()}; path=/`;
        window.location.reload();
    }
}
