import { Controller, ActionEvent } from "@hotwired/stimulus";

export default class extends Controller {
    toggleCopied(target: EventTarget | null) {
        if (target instanceof HTMLElement) {
            const innerHtml = target.innerHTML;
            target.innerHTML = "Copied!";
            setTimeout(() => {
                target.innerHTML = innerHtml;
            }, 3000);
        }
    }

    copyImg(event: ActionEvent): void {
        const { url } = event.params;
        const text = `<img src="${url}"/>`;
        navigator.clipboard.writeText(text).then(() => this.toggleCopied(event.target));
    }

    copyUrl(event: ActionEvent): void {
        const { url } = event.params;
        const u = new URL(url);
        const text = u.pathname;
        navigator.clipboard.writeText(text).then(() => this.toggleCopied(event.target));
    }

    copyGoFunc(event: ActionEvent): void {
        const { name } = event.params;
        const text = `svg("${name}")`;
        navigator.clipboard.writeText(text).then(() => this.toggleCopied(event.target));
    }

    copyTplFunc(event: ActionEvent): void {
        const { name } = event.params;
        const text = `{{svg "${name}"}}`;
        navigator.clipboard.writeText(text).then(() => this.toggleCopied(event.target));
    }
}
