import { Controller } from "@hotwired/stimulus";

// converts the vertical scroll into a horizontal scroll.
export default class extends Controller {

    connect(): void {
        this.element.addEventListener('wheel', this.scroll)
    }

    disconnect(): void {
        this.element.removeEventListener('wheel', this.scroll)
    }

    scroll = (ev: Event) => {
        const event = ev as WheelEvent
        event.preventDefault()
        this.element.scrollLeft += event.deltaY
        this.element.scrollLeft += event.deltaX
    }
}