import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    updateSrc(event) {
        const { id, src } = event.params;
        const selector = `turbo-frame#${id}`;
        const tfs = document.querySelectorAll(selector);
        if (tfs.length > 1) {
            console.warn(`found more then one turbe-frame with id=${id}`);
            return;
        }
        if (!tfs.length) {
            console.warn(`couldn't find any turbo-frame for selector ${selector}`);
            return;
        }
        const tf = tfs[0];
        tf.src = src;
    }

    reload(event) {
        const { id } = event.params;
        const selector = `turbo-frame#${id}`;
        const tfs = document.querySelectorAll(selector);
        if (tfs.length != 1) {
            console.warn(`found more then one or none turbe-frame with id=${id}`);
            return;
        }
        const tf = tfs[0];
        tf.reload();
    }
}
