import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.check();
    }
    check() {
        if (!this.element.value) {
            this.element.setAttribute("data-is-empty", "");
        } else {
            this.element.removeAttribute("data-is-empty");
        }
    }
}
