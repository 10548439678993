import { Controller } from "@hotwired/stimulus";

const MATCH_LINK = /([^@]+)@(.*)/;

export default class extends Controller {
    static values = {
        target: String,
        names: String,
        hasLinks: String,
    };

    hasAttribute = {};
    hasObservers = [];

    connect() {
        const hasLinks = this.hasLinksValue;
        if (hasLinks) {
            const links = hasLinks.split(" ").map((link) => {
                const [_, attribute, targetSelector] = MATCH_LINK.exec(link);
                return { attribute, targetSelector };
            });
            for (const link of links) {
                const target = document.querySelector(link.targetSelector);
                if (target.hasAttribute(link.attribute)) {
                    if (this.hasAttribute[link.attribute]) {
                        this.hasAttribute[link.attribute]++;
                    } else {
                        this.hasAttribute[link.attribute] = 1;
                    }
                } else {
                    this.hasAttribute[link.attribute] = 0;
                }
            }
            for (const [attributeName, numberOf] of Object.entries(this.hasAttribute)) {
                if (numberOf > 0) {
                    this.element.setAttribute(attributeName, "true");
                } else {
                    this.element.removeAttribute(attributeName);
                }
            }
            for (const link of links) {
                const observer = new MutationObserver((mutationList) => {
                    for (const mutation of mutationList) {
                        const target = mutation.target;
                        const attributeName = mutation.attributeName;
                        if (mutation.type === "attributes") {
                            if (target.hasAttribute(attributeName)) {
                                this.hasAttribute[attributeName]++;
                            } else {
                                this.hasAttribute[attributeName]--;
                            }
                            if (this.hasAttribute[attributeName] === 0) {
                                this.element.removeAttribute(attributeName);
                            } else {
                                this.element.setAttribute(attributeName, "true");
                            }
                        }
                    }
                });
                const target = document.querySelector(link.targetSelector);
                observer.observe(target, { attributeFilter: [link.attribute], attributeOldValue: true });
                this.hasObservers.push(observer);
            }
        } else {
            const target = document.querySelector(this.targetValue);
            const attributes = this.namesValue.split(" ").map((n) => n.trim());
            for (const name of attributes) {
                if (!target.hasAttribute(name)) {
                    this.element.removeAttribute(name);
                } else {
                    const v = target.getAttribute(name) || "";
                    if (!v) {
                        this.element.setAttribute(name, "");
                    } else {
                        this.element.setAttribute(name, v);
                    }
                }
            }
            this.observer = new MutationObserver((mutationList) => {
                for (const mutation of mutationList) {
                    const name = mutation.attributeName;
                    if (mutation.type === "attributes") {
                        if (!target.hasAttribute(name)) {
                            this.element.removeAttribute(name);
                        } else {
                            const v = target.getAttribute(name);
                            if (!v) {
                                this.element.setAttribute(name, "");
                            } else {
                                this.element.setAttribute(name, v);
                            }
                        }
                    }
                }
            });
            this.observer.observe(target, { attributeFilter: attributes });
        }
    }

    disconnect() {
        if (this.observer) {
            this.observer.disconnect();
        }

        for (const observer of this.hasObservers) {
            observer.disconnect();
        }
    }
}
