import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        class: String,
    };
    declare readonly classValue: string;

    static targets = ["add"];
    declare readonly addTargets: HTMLElement[];

    initialize(): void {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > 0) {
                    this.addTargets.forEach((element) => {
                        element.classList.add(this.classValue);
                    });
                }
            });
        });
        observer.observe(this.element);
    }
}
