import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["favorite"];

    share() {
        navigator.share({
            title: document.title,
            url: location.href,
            text: document.querySelector('meta[name="description"]')?.innerHTML,
        });
    }

    openPrintWindow() {
        window.print();
    }
}
