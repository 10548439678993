import { ActionEvent, Controller } from "@hotwired/stimulus";

export default class extends Controller {
    copy(event: ActionEvent): void {
        const { text } = event.params;
        navigator.clipboard.writeText(text).then(() => {
            this.dispatch("copied", { detail: text });
        });
    }
}
