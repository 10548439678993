import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["header", "panel"];
    static classes = ["icon"];

    initialize() {
        const panels = this.panelTargets;

        panels.forEach((panel) => {
            panel.style.maxHeight = panel.scrollHeight + "px";
        });

        window.addEventListener(
            "resize",
            () => {
                panels.forEach((panel) => {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                });
            },
            { passive: true }
        );
    }

    toggleAccordion(event) {
        const headers = this.headerTargets;

        const index = headers.indexOf(event.currentTarget);

        event.currentTarget.children[1].classList.toggle(this.iconClass);

        const panels = this.panelTargets;

        if (panels[index].style.maxHeight === "0px") {
            panels[index].style.maxHeight = panels[index].scrollHeight + "px";
            setTimeout(() => {
                panels[index].style.maxHeight = "auto";
            }, 300);
        } else {
            panels[index].style.maxHeight = "0px";
        }
    }
}
