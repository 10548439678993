import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["viewPort", "scrollDownIndicator", "termsAndConditions", "addButton"];

    termsAndConditionsTargetConnected(element) {
        this.observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    if (this.hasScrollDownIndicatorTarget) {
                        this.scrollDownIndicatorTarget.setAttribute("hidden", "");
                    }
                    if (this.hasAddButtonTarget) {
                        this.addButtonTarget.removeAttribute("disabled");
                    }
                }
            },
            {
                root: this.viewPortTarget,
                rootMargin: "0px",
                threshold: 0.7,
            }
        );
        this.observer.observe(element);
    }

    termsAndConditionsTargetDisconnected() {
        this.observer.disconnect();
    }
}
