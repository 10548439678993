import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = { startViewId: String };

    connect() {
        this.activeView = this.startViewIdValue;
    }

    getViewAndButton(viewId) {
        const viewSelector = `[data-${this.identifier}-view=${viewId}]`;
        const buttonSelector = `[data-${this.identifier}-button-for-view=${viewId}]`;
        return [document.querySelector(viewSelector), document.querySelector(buttonSelector)];
    }

    switchView(event) {
        const { viewId } = event.params;
        if (this.activeView) {
            const [view, button] = this.getViewAndButton(this.activeView);
            view.setAttribute("hidden", "true");
            button.removeAttribute("aria-selected");
        }
        const [view, button] = this.getViewAndButton(viewId);
        view.removeAttribute("hidden");
        button.setAttribute("aria-selected", "true");
        this.activeView = viewId;
    }
}
