import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    page(event) {
        const { withUrl } = event.params;
        if (withUrl) {
            location.assign(withUrl);
            if (location.hash !== "") {
                location.reload();
            }
        } else {
            location.reload();
        }
    }
}
