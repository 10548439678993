import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    addSelectItemEventToDataLayer(event) {
        const itemIndex = event.params.itemIndex;
        const dl = window.dataLayer;
        if (dl) {
            for (const layer of dl) {
                if (layer.event === "view_item_list") {
                    const newLayer = {
                        event: "select_item",
                        item_list_id: layer.ecommerce.item_list_id,
                        item_list_name: layer.ecommerce.item_list_name,
                        items: [layer.ecommerce.items[itemIndex]],
                    };
                    dl.push({ ecommerce: null });
                    dl.push(newLayer);
                    return;
                }
            }
        }
    }
}
