import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    open() {
        window.Cello("open");
    }
    shutdown() {
        window.Cello("shutdown");
    }
}
