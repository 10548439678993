import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [
        "fixedTopBar",
        "featuredImages",
        "bookingStart",
        "fixedTopBarBooking",
        "highlightsSection",
        "requirementsSection",
        "includedSection",
        "feesSection",
        "reviewsSection",
        "highlightsJumpButton",
        "requirementsJumpButton",
        "includedJumpButton",
        "feesJumpButton",
        "reviewsJumpButton",
    ];

    declare featuredImagesObserver: IntersectionObserver;
    declare bookingStartObserver: IntersectionObserver;
    declare sectionIndicatorObserver: IntersectionObserver;

    declare fixedTopBarTarget: HTMLElement;
    declare fixedTopBarBookingTarget: HTMLElement;

    declare highlightsSectionTarget: HTMLElement;
    declare requirementsSectionTarget: HTMLElement;
    declare includedSectionTarget: HTMLElement;
    declare feesSectionTarget: HTMLElement;
    declare reviewsSectionTarget: HTMLElement;
    declare highlightsJumpButtonTarget: HTMLElement;
    declare requirementsJumpButtonTarget: HTMLElement;
    declare includedJumpButtonTarget: HTMLElement;
    declare feesJumpButtonTarget: HTMLElement;
    declare reviewsJumpButtonTarget: HTMLElement;

    declare hasHighlightsSectionTarget: boolean;
    declare hasRequirementsSectionTarget: boolean;
    declare hasIncludedSectionTarget: boolean;
    declare hasFeesSectionTarget: boolean;
    declare hasReviewsSectionTarget: boolean;

    setupSectionIndicatorObserver(): void {
        this.sectionIndicatorObserver = new IntersectionObserver(
            (entries) => {
                let found = false;
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !found) {
                        found = true;
                        const targetAttributeName = `data-${this.identifier}-target`;
                        const target = entry.target.getAttribute(targetAttributeName);

                        if (this.hasHighlightsSectionTarget) {
                            this.highlightsJumpButtonTarget.classList.add("border-b-white");
                            this.highlightsJumpButtonTarget.classList.remove("border-b-blue");
                        }
                        if (this.hasRequirementsSectionTarget) {
                            this.requirementsJumpButtonTarget.classList.add("border-b-white");
                            this.requirementsJumpButtonTarget.classList.remove("border-b-blue");
                        }
                        if (this.hasIncludedSectionTarget) {
                            this.includedJumpButtonTarget.classList.add("border-b-white");
                            this.includedJumpButtonTarget.classList.remove("border-b-blue");
                        }
                        if (this.hasFeesSectionTarget) {
                            this.feesJumpButtonTarget.classList.add("border-b-white");
                            this.feesJumpButtonTarget.classList.remove("border-b-blue");
                        }
                        if (this.hasReviewsSectionTarget) {
                            this.reviewsJumpButtonTarget.classList.add("border-b-white");
                            this.reviewsJumpButtonTarget.classList.remove("border-b-blue");
                        }

                        if (target === "highlightsSection") {
                            this.highlightsJumpButtonTarget.classList.remove("border-b-white");
                            this.highlightsJumpButtonTarget.classList.add("border-b-blue");
                        } else if (target === "requirementsSection") {
                            this.requirementsJumpButtonTarget.classList.remove("border-b-white");
                            this.requirementsJumpButtonTarget.classList.add("border-b-blue");
                        } else if (target === "includedSection") {
                            this.includedJumpButtonTarget.classList.remove("border-b-white");
                            this.includedJumpButtonTarget.classList.add("border-b-blue");
                        } else if (target === "feesSection") {
                            this.feesJumpButtonTarget.classList.remove("border-b-white");
                            this.feesJumpButtonTarget.classList.add("border-b-blue");
                        } else if (target === "reviewsSection") {
                            this.reviewsJumpButtonTarget.classList.remove("border-b-white");
                            this.reviewsJumpButtonTarget.classList.add("border-b-blue");
                        }
                    }
                });
            },
            {
                rootMargin: "-44% 0px -55% 0px",
                threshold: 0,
            }
        );
        if (this.hasHighlightsSectionTarget) {
            this.sectionIndicatorObserver.observe(this.highlightsSectionTarget);
        }
        if (this.hasRequirementsSectionTarget) {
            this.sectionIndicatorObserver.observe(this.requirementsSectionTarget);
        }
        if (this.hasIncludedSectionTarget) {
            this.sectionIndicatorObserver.observe(this.includedSectionTarget);
        }
        if (this.hasFeesSectionTarget) {
            this.sectionIndicatorObserver.observe(this.feesSectionTarget);
        }
        if (this.hasReviewsSectionTarget) {
            this.sectionIndicatorObserver.observe(this.reviewsSectionTarget);
        }
    }

    featuredImagesObserverCallback(entries): void {
        const entry = entries[0];
        if (!entry.isIntersecting) {
            this.fixedTopBarTarget.classList.add("sm:flex");
        } else {
            this.fixedTopBarTarget.classList.remove("sm:flex");
        }
    }

    bookingStartObserverCallback(entries): void {
        const entry = entries[0];
        if (!entry.isIntersecting) {
            this.fixedTopBarBookingTarget.classList.add("sm:block");
        } else {
            this.fixedTopBarBookingTarget.classList.remove("sm:block");
        }
    }

    featuredImagesTargetConnected(element: HTMLElement): void {
        this.featuredImagesObserver = new IntersectionObserver(this.featuredImagesObserverCallback.bind(this));
        this.featuredImagesObserver.observe(element);
    }

    bookingStartTargetConnected(element: HTMLElement): void {
        const rootMargin = `0px`;
        this.bookingStartObserver = new IntersectionObserver(this.bookingStartObserverCallback.bind(this), {
            rootMargin: rootMargin,
        });
        this.bookingStartObserver.observe(element);
    }

    connect(): void {
        this.setupSectionIndicatorObserver();
    }

    disconnect(): void {
        this.featuredImagesObserver.disconnect();
        this.bookingStartObserver.disconnect();
        this.sectionIndicatorObserver.disconnect();
    }
}
