import { Controller } from "@hotwired/stimulus";
import morphdom from "morphdom";

export default class extends Controller {
    eventListener: EventListener;
    ids: Set<string>;

    declare readonly idsValue: string;
    static values = {
        ids: { type: String, default: "" },
    };

    setMorphdomRenderer(event: EventListener & { detail: { render: typeof morphdom; newFrame: Element } }) {
        if (this.ids.has(event.detail.newFrame.id)) {
            event.detail.render = (from, to) => {
                morphdom(from, to, { childrenOnly: true });
            };
        }
    }

    initialize(): void {
        this.ids = new Set();
        for (const id of this.idsValue.split(",")) {
            this.ids.add(id.trim());
        }
    }

    connect(): void {
        this.eventListener = this.setMorphdomRenderer.bind(this);
        addEventListener("turbo:before-frame-render", this.eventListener);
    }

    disconnect(): void {
        removeEventListener("turbo:before-frame-render", this.eventListener);
    }
}
