import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["tabs", "data", "topDestinations", "africa", "asia", "america", "europeOceania"];
    static classes = ["active"];

    changeTab(event) {
        const tabs = Array.from(this.tabsTarget.children);

        tabs.forEach((tab) => {
            tab.classList.remove(this.activeClass);
        });

        const index = tabs.indexOf(event.target);

        event.target.classList.add(this.activeClass);
        if (index > 4) {
            tabs[index - 5].classList.add(this.activeClass);
        } else {
            tabs[index + 5].classList.add(this.activeClass);
        }

        const panels = Array.from(this.dataTarget.children);

        panels.forEach((panel) => {
            panel.classList.add("hidden");
        });

        if (index > 4) {
            panels[index - 5].classList.remove("hidden");
        } else {
            panels[index].classList.remove("hidden");
        }
    }

    changeTabToAfrica() {
        const tabs = Array.from(this.tabsTarget.children);

        tabs.forEach((tab) => {
            tab.classList.remove(this.activeClass);
        });

        tabs[1].classList.add(this.activeClass);
        tabs[6].classList.add(this.activeClass);

        const panels = Array.from(this.dataTarget.children);

        panels.forEach((panel) => {
            panel.classList.add("hidden");
        });

        this.africaTarget.classList.remove("hidden");
    }

    changeTabToAsia() {
        const tabs = Array.from(this.tabsTarget.children);

        tabs.forEach((tab) => {
            tab.classList.remove(this.activeClass);
        });

        tabs[2].classList.add(this.activeClass);
        tabs[7].classList.add(this.activeClass);

        const panels = Array.from(this.dataTarget.children);

        panels.forEach((panel) => {
            panel.classList.add("hidden");
        });

        this.asiaTarget.classList.remove("hidden");
    }

    changeTabToAmerica() {
        const tabs = Array.from(this.tabsTarget.children);

        tabs.forEach((tab) => {
            tab.classList.remove(this.activeClass);
        });

        tabs[3].classList.add(this.activeClass);
        tabs[8].classList.add(this.activeClass);

        const panels = Array.from(this.dataTarget.children);

        panels.forEach((panel) => {
            panel.classList.add("hidden");
        });

        this.americaTarget.classList.remove("hidden");
    }

    changeTabToEuropeOceania() {
        const tabs = Array.from(this.tabsTarget.children);

        tabs.forEach((tab) => {
            tab.classList.remove(this.activeClass);
        });

        tabs[4].classList.add(this.activeClass);
        tabs[9].classList.add(this.activeClass);

        const panels = Array.from(this.dataTarget.children);

        panels.forEach((panel) => {
            panel.classList.add("hidden");
        });

        this.europeOceaniaTarget.classList.remove("hidden");
    }
}
