import { ActionEvent, Controller } from "@hotwired/stimulus";

export default class extends Controller {
    to(event: ActionEvent) {
        event.preventDefault();
        const { meId: scrollMeId, to: scrollToSelector, offset = 0, behavior = "auto" } = event.params;

        let scrollMeSelector = "html";
        if (scrollMeId) {
            scrollMeSelector = `[data-${this.identifier}-me-id=${scrollMeId}]`;
        }
        const scrollMe: HTMLElement | null = document.querySelector(scrollMeSelector);
        if (!scrollMe) {
            console.warn(`couldn't find element for ${scrollMeSelector}`);
            return;
        }

        let scrollY = 0;
        let scrollMeTop = 0;
        if (scrollMeSelector == "html") {
            scrollY = window.scrollY;
        } else {
            scrollY = scrollMe.scrollTop;
            const scrollMeBounds = scrollMe.getBoundingClientRect();
            scrollMeTop = scrollMeBounds.top;
        }

        const toElement = document.querySelector(scrollToSelector);
        if (!toElement) {
            console.warn(`couldn't find element for ${scrollToSelector}`);
            return;
        }
        const compBounds = toElement.getBoundingClientRect();
        const newTop = compBounds.top - scrollMeTop + scrollY + offset;

        scrollMe.scrollTo({
            behavior: behavior,
            left: 0,
            top: newTop,
        });
    }

    toX(event: ActionEvent) {
        event.preventDefault();
        const { meId: scrollMeId, to: scrollToSelector, offset = 0, behavior = "auto" } = event.params;

        let scrollMeSelector = "html";
        if (scrollMeId) {
            scrollMeSelector = `[data-${this.identifier}-me-id=${scrollMeId}]`;
        }
        const scrollMe: HTMLElement | null = document.querySelector(scrollMeSelector);
        if (!scrollMe) {
            console.warn(`couldn't find element for ${scrollMeSelector}`);
            return;
        }

        const toElement = document.querySelector(scrollToSelector);
        if (!toElement) {
            console.warn(`couldn't find element for ${scrollToSelector}`);
            return;
        }
        const compBounds = toElement.getBoundingClientRect();
        const newLeft = compBounds.left + window.scrollX + offset;
        scrollMe.scrollTo({
            behavior: behavior,
            left: newLeft,
            top: 0,
        });
    }
}
