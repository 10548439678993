import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        duration: {
            type: Number,
            default: 5,
        },
    };

    declare durationValue: number;

    connect() {
        setTimeout(() => {
            this.element.remove();
        }, this.durationValue * 1000);
    }
}
