import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["input", "item", "text", "startView", "filteredList"];
    static values = {
        numbOfItems: { type: Number, default: 5 },
    };
    static classes = ["filtering"];

    connect() {
        if (this.hasStartViewTarget && this.hasInputTarget && this.inputTarget.value === "") {
            this.filteredListTarget.hidden = true;
            if (this.hasStartViewTarget) {
                this.startViewTarget.hidden = false;
            }
        } else {
            let numberActiveItems = 0;
            this.itemTargets.forEach((item) => {
                numberActiveItems++;
                if (numberActiveItems > this.numbOfItemsValue) {
                    item.hidden = true;
                } else {
                    item.hidden = false;
                }
            });
        }
        if (this.hasInputTarget && this.inputTarget.value !== "") {
            this.apply();
        }
    }

    apply() {
        const input = this.inputTarget.value.toLowerCase();

        if (input === "") {
            this.element.classList.remove(this.filteringClass);
            if (this.hasStartViewTarget) {
                this.filteredListTarget.hidden = true;
                this.startViewTarget.hidden = false;
            }
        } else {
            this.element.classList.add(this.filteringClass);
            if (this.hasStartViewTarget) {
                this.filteredListTarget.hidden = false;
                this.startViewTarget.hidden = true;
            }
        }

        let numberActiveItems = 0;
        this.itemTargets.forEach((item, index) => {
            const value = this.textTargets[index].innerHTML.toLowerCase();
            item.hidden = true;
            if (value.includes(input)) {
                numberActiveItems++;
                if (numberActiveItems <= this.numbOfItemsValue) {
                    item.hidden = false;
                }
            }
        });
    }
}
