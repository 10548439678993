import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["quickFilterOverflowContainer", "quickFilterScrollToEndBtn"];
    scrollQuickFilterToEnd(event) {
        this.quickFilterOverflowContainerTarget.scrollTo(500, 0);
        event.currentTarget.setAttribute("hidden", "");
    }

    quickFilterOverflowContainerTargetConnected(element) {
        element.addEventListener("scroll", () => {
            if (element.scrollLeft != 0 && !this.quickFilterScrollToEndBtnTarget.hasAttribute("hidden")) {
                this.quickFilterScrollToEndBtnTarget.setAttribute("hidden", "");
            } else if (element.scrollLeft == 0) {
                this.quickFilterScrollToEndBtnTarget.removeAttribute("hidden");
            }
        });
    }
}
