import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["input"];

    static values = {
        prefix: String,
    };

    buildName(name) {
        if (this.prefixValue) {
            name = `${this.prefixValue}:${name}`;
        }
        return name;
    }

    inputTargetConnected(element) {
        const currentValue = element.value;
        if (currentValue) {
            return;
        }
        const name = this.buildName(element.name);
        if (!name) {
            return;
        }
        const value = localStorage.getItem(name);
        if (!value) {
            return;
        }
        element.value = value;
    }

    _setItem(name, value) {
        if (!name) {
            return;
        }
        if (!value) {
            return;
        }
        localStorage.setItem(this.buildName(name), value);
    }

    setItem(event) {
        this._setItem(event.target.name, event.target.value);
    }

    _removeItem(name) {
        if (!name) {
            return;
        }
        localStorage.removeItem(this.buildName(name));
    }

    removeItem(event) {
        let name = event.target.name;
        this._removeItem(name);
    }

    storeInputValues() {
        for (const i of this.inputTargets) {
            this._setItem(i.name, i.value);
        }
    }

    removeInputValues() {
        for (const i of this.inputTargets) {
            this._removeItem(i.name);
        }
    }
}
