import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    removeParam(event) {
        const { remove } = event.params;
        const newUrl = new URL(location.toString());
        newUrl.searchParams.delete(remove);
        history.replaceState(null, "", newUrl.toString());
    }
}
