import { Controller, ActionEvent } from "@hotwired/stimulus";

type DialogListeners = {
    closeHandler: (event: Event) => void;
    backdropCloseHandler: (event: Event) => void;
    closeViaEvent: (event: Event) => void;
};

export default class extends Controller {
    static targets = ["openOnConnect"];

    bodyOverflow: string;

    dialogs: Map<HTMLDialogElement, DialogListeners> = new Map();

    globalShowModalHandler: (event: Event | CustomEvent) => void;

    _globalShowModalHandler(event: Event | CustomEvent) {
        if (event instanceof CustomEvent) {
            const { dialogId } = event.detail;
            this._showModalWithId(dialogId);
        }
    }

    connect() {
        const h = this._globalShowModalHandler.bind(this);
        addEventListener(`${this.identifier}:show-modal`, h);
    }

    disconnect() {
        removeEventListener(`${this.identifier}:show-modal`, this.globalShowModalHandler);
    }

    openOnConnectTargetConnected(dialog: HTMLDialogElement) {
        this._showModal(dialog);
    }

    openOnConnectTargetDisconnected(dialog: HTMLDialogElement) {
        dialog.close();
    }

    closeHandler(dialog: HTMLDialogElement) {
        return () => {
            const eventHandler = this.dialogs.get(dialog);
            const targetId = dialog.getAttribute(`data-${this.identifier}-id`);
            if (eventHandler) {
                dialog.removeEventListener("close", eventHandler.closeHandler);
                dialog.removeEventListener("click", eventHandler.backdropCloseHandler);
                removeEventListener(`dialog:close-${targetId}`, eventHandler.closeViaEvent);
                this.dialogs.delete(dialog);
                if (this.dialogs.size === 0) {
                    document.body.style.overflow = this.bodyOverflow;
                }
            }
            const eventName = `${targetId}-closed`;
            this.dispatch(eventName);
        };
    }

    backdropCloseHandler(dialog: HTMLDialogElement) {
        return (event: Event): void => {
            if (event.target instanceof HTMLElement && event.target.nodeName === "DIALOG") {
                event.stopPropagation();
                dialog.close();
                const dialogId = dialog.getAttribute("data-dialog-id");
                const eventName = `${dialogId}-closed-via-backdrop`;
                this.dispatch(eventName);
            }
        };
    }

    closeViaEventHandler(dialog: HTMLDialogElement) {
        return (): void => {
            dialog.close();
            const dialogId = dialog.getAttribute("data-dialog-id");
            const eventName = `${dialogId}-closed-via-backdrop`;
            this.dispatch(eventName);
        };
    }

    _showModal(dialog: HTMLDialogElement) {
        const targetId = dialog.getAttribute(`data-${this.identifier}-id`);
        if (this.dialogs.size === 0) {
            this.bodyOverflow = document.body.style.overflow;
        }
        document.body.style.overflow = "hidden";
        dialog.showModal();
        const eventName = `${targetId}-open`;
        this.dispatch(eventName);
        if (!this.dialogs.has(dialog)) {
            const closeHandler = this.closeHandler(dialog).bind(this);
            const backdropCloseHandler = this.backdropCloseHandler(dialog).bind(this);
            const closeViaEventHandler = this.closeViaEventHandler(dialog).bind(this);
            this.dialogs.set(dialog, {
                closeHandler: closeHandler,
                backdropCloseHandler: backdropCloseHandler,
                closeViaEvent: closeViaEventHandler,
            });
            dialog.addEventListener("close", closeHandler);
            dialog.addEventListener("click", backdropCloseHandler);
            addEventListener(`dialog:close-${targetId}`, closeViaEventHandler);
        }
    }

    _showModalWithId(targetId: string) {
        const dialog: HTMLDialogElement | null = document.querySelector(
            `dialog[data-${this.identifier}-id=${targetId}]`,
        );
        if (!dialog) {
            console.warn(
                `couldn't find dialog for id ${targetId}, missing dialog with data attribute data-dialog-id=${targetId}`,
            );
            return;
        }
        this._showModal(dialog);
    }

    showModal(event: ActionEvent) {
        const { targetId } = event.params;
        this._showModalWithId(targetId);
    }

    closeModal(event: ActionEvent) {
        const { targetId } = event.params;
        const dialog: HTMLDialogElement | null = document.querySelector(
            `dialog[data-${this.identifier}-id=${targetId}]`,
        );
        if (!dialog) {
            console.warn(
                `couldn't find dialog for id ${targetId} (missing dialog with data attribute data-dialog-id=${targetId}`,
            );
            return;
        }
        dialog.close();
    }

    show(event: ActionEvent) {
        const { targetId } = event.params;
        const dialog: HTMLDialogElement | null = document.querySelector(
            `dialog[data-${this.identifier}-id=${targetId}]`,
        );
        if (!dialog) {
            console.warn(
                `couldn't find dialog for id ${targetId}, missing dialog with data attribute data-dialog-id=${targetId}`,
            );
            return;
        }
        dialog.show();
    }
}
