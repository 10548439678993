import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.check();
    }
    check() {
        const selectedOptions = this.element.selectedOptions;
        if (selectedOptions && selectedOptions.length > 0 && selectedOptions[0].value === "") {
            this.element.setAttribute("is-placeholder-selected", "");
        } else {
            this.element.removeAttribute("is-placeholder-selected");
        }
    }
}
