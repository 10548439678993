import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["menu", "button"];
    declare menuTarget: HTMLElement;
    declare buttonTarget: HTMLElement;

    isOpen: boolean = false;
    clickEventHandler: () => void;

    zindexContainerSelector = `[data-${this.identifier}-zindex-container]`;

    close() {
        this.isOpen = false;
        const zindexContainer = document.querySelector(this.zindexContainerSelector);
        if (zindexContainer) {
            zindexContainer.classList.remove("z-50");
        }
        this.menuTarget.classList.add("hidden");
        this.menuTarget.classList.remove("block");
        this.buttonTarget.classList.add("shadow-sm");
        this.buttonTarget.classList.remove("shadow-md");
    }

    open() {
        this.isOpen = true;
        const zindexContainer = document.querySelector(this.zindexContainerSelector);
        if (zindexContainer) {
            zindexContainer.classList.add("z-50");
        }
        this.menuTarget.classList.add("block");
        this.menuTarget.classList.remove("hidden");
        this.buttonTarget.classList.remove("shadow-sm");
        this.buttonTarget.classList.add("shadow-md");
        this.clickEventHandler = this.close.bind(this);
        setTimeout(() => {
            document.body.addEventListener("click", this.clickEventHandler, { once: true });
        }, 0);
    }

    toggle() {
        if (!this.isOpen) {
            this.open();
            return;
        }
    }
}
