import { Controller } from "@hotwired/stimulus";
import { setCookie, getCookie } from "./cookies";

export default class extends Controller {
    static values = {
        type: String,
    };
    static targets = ["message", "requestId"];

    decodeBase64String(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(
            atob(str)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );
    }

    connect() {
        if (this.typeValue == "") {
            const [value, found] = getCookie("flash");
            if (!found) {
                return;
            }
            const text = this.decodeBase64String(value);
            const textNode = document.createTextNode(text);
            let targetElement = this.element;
            if (this.hasMessageTarget) {
                targetElement = this.messageTarget;
            }
            targetElement.appendChild(textNode);
            this.element.removeAttribute("hidden");
            setCookie("flash", "", { path: "/", "max-age": "-1" });
        } else if (this.typeValue === "error") {
            const [value, found] = getCookie("error-flash");
            if (!found) {
                return;
            }
            const jsonString = this.decodeBase64String(value);
            const obj = JSON.parse(jsonString);
            if (obj.requestId && obj.requestId !== "" && this.hasRequestIdTarget) {
                const textNode = document.createTextNode(obj.requestId);
                this.requestIdTarget.appendChild(textNode);
            }
            let targetElement = this.element;
            if (this.hasMessageTarget) {
                targetElement = this.messageTarget;
            }
            const textNode = document.createTextNode(obj.message);
            targetElement.appendChild(textNode);
            this.element.removeAttribute("hidden");
            setCookie("error-flash", "", { path: "/", "max-age": "-1" });
        } else {
            throw new Error(`unknown flash type ${this.typeValue}`);
        }
    }
}
