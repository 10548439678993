import { Controller } from "@hotwired/stimulus";
import { Swiper, SwiperOptions, Pagination, Navigation } from "swiper";
import "swiper/css/bundle";
import "swiper/css/pagination";

export default class extends Controller<HTMLElement> {
    swiper?: Swiper;
    declare readonly optionsValue: SwiperOptions;

    static values = {
        options: Object,
    };

    connect(): void {
        this.swiper = new Swiper(this.element, {
            modules: [Navigation, Pagination],
            ...this.defaultOptions,
            ...this.optionsValue,
        });
    }

    disconnect(): void {
        if (this.swiper) {
            this.swiper.destroy();
            this.swiper = undefined;
        }
    }

    get defaultOptions(): SwiperOptions {
        return {};
    }
}
