import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        ends: String,
    };

    static targets = ["countdown"];

    didEnd(endDate) {
        const end = new Date(endDate);
        const diff = end.getTime() - Date.now();
        return diff <= 0;
    }

    formatCountdown(endDate) {
        const end = new Date(endDate);
        const diff = end.getTime() - Date.now();
        const dateDiff = new Date(diff);
        const daysLeft = dateDiff.getUTCDate();
        const dateStringPart = daysLeft > 1 ? `${daysLeft}D ` : "";
        const hoursStringPart = dateDiff.getUTCHours().toString().padStart(2, "0");
        const minutesStringPart = dateDiff.getUTCMinutes().toString().padStart(2, "0");
        const secondsStringPart = dateDiff.getUTCSeconds().toString().padStart(2, "0");

        return `${dateStringPart}${hoursStringPart}:${minutesStringPart}:${secondsStringPart}`;
    }

    startTicking(endDate) {
        const intervalNumber = setInterval(() => {
            if (this.didEnd(endDate)) {
                clearInterval(intervalNumber);
                this.element.remove();
            }

            this.countdownTarget.innerText = this.formatCountdown(endDate);
        }, 1000);
    }

    initialize() {
        const end = this.endsValue;

        if (this.didEnd(end)) {
            this.element.remove();
        } else {
            this.countdownTarget.innerText = this.formatCountdown(end);
            this.startTicking(end);
        }
    }
}
