import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = { bootOnConnect: Boolean };

    _boot(forceBoot) {
        var intercomSettingsElement = document.getElementById("intercom-settings");
        if (intercomSettingsElement) {
            var intercomSettingsAsJson = intercomSettingsElement.textContent;
            var newIntercomSettings = JSON.parse(intercomSettingsAsJson);
            if (!window.intercomSettings) {
                window.intercomSettings = {};
            }
            Object.assign(window.intercomSettings, newIntercomSettings);
            var ic = window.Intercom;
            if (typeof ic === "function") {
                if (!ic.booted || forceBoot) {
                    ic("boot", window.intercomSettings);
                }
            } else {
                if (!ic.booted || forceBoot) {
                    var i = function () {
                        i.c(arguments);
                    };
                    i.q = [];
                    i.c = function (args) {
                        i.q.push(args);
                    };
                    window.Intercom = i;
                    i("boot", window.intercomSettings);
                }
            }
        }
    }

    boot() {
        this._boot(false);
    }

    shutdown() {
        var ic = window.Intercom;
        if (typeof ic === "function") {
            ic("shutdown");
        }
    }

    connect() {
        if (this.bootOnConnectValue) {
            this._boot(false);
        }
    }
}
