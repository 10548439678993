import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        latitude: Number,
        longitude: Number,
        zoom: {
            type: Number,
            default: 0,
        },
        category: String,
        options: {
            type: Object,
            default: {},
        },
        loading: String,
        useDataset: Boolean,
    };
    declare readonly latitudeValue: number;
    declare readonly longitudeValue: number;
    declare readonly zoomValue: number;
    declare readonly categoryValue: string;
    declare readonly optionsValue: Object;
    declare readonly loadingValue: string;
    declare readonly useDatasetValue: Boolean;

    intersectionObserver: IntersectionObserver | undefined;

    leafLetScriptUrl = "https://unpkg.com/leaflet@1.8.0/dist/leaflet.js";
    leafLetCssUrl = "https://unpkg.com/leaflet@1.8.0/dist/leaflet.css";

    isAppended: Boolean = false;

    appendAdditionalLeafLetScripts(): void {
        const markerClusterScript = document.createElement("script");
        markerClusterScript.addEventListener("load", () => {
            this.loadMap();
        });
        markerClusterScript.src = "https://unpkg.com/leaflet.markercluster@1.4.1/dist/leaflet.markercluster.js";
        document.head.append(markerClusterScript);
        this.isAppended = true;

        const markerClusterStyles = document.createElement("link");
        markerClusterStyles.rel = "stylesheet";
        markerClusterStyles.href = "https://unpkg.com/leaflet.markercluster@1.4.1/dist/MarkerCluster.css";
        document.head.append(markerClusterStyles);

        const markerClusterDefaultStyles = document.createElement("link");
        markerClusterDefaultStyles.rel = "stylesheet";
        markerClusterDefaultStyles.href =
            "https://unpkg.com/leaflet.markercluster@1.5.3/dist/MarkerCluster.Default.css";
        document.head.append(markerClusterDefaultStyles);
    }

    appendLeafLetScript(): void {
        const leafletScript = document.createElement("script");
        leafletScript.integrity =
            "sha512-BB3hKbKWOc9Ez/TAwyWxNXeoV9c1v6FIeYiBieIWkpLjauysF18NzgR1MBNBXf8/KABdlkX68nAhlwcDFLGPCQ==";
        leafletScript.crossOrigin = "";
        leafletScript.src = this.leafLetScriptUrl;
        leafletScript.addEventListener("load", () => {
            if (this.useDatasetValue) {
                this.appendAdditionalLeafLetScripts();
                return;
            }
            this.loadMap();
        });
        document.head.append(leafletScript);
        this.isAppended = true;

        const leafletStyles = document.createElement("link");
        leafletStyles.rel = "stylesheet";
        leafletStyles.integrity =
            "sha512-hoalWLoI8r4UszCkZ5kL8vayOGVae1oxXe/2A4AO6J9+580uKHDO3JdHb7NzwwzK5xr/Fs0W40kiNHxM9vyTtQ==";
        leafletStyles.href = this.leafLetCssUrl;
        leafletStyles.crossOrigin = "";
        document.head.append(leafletStyles);
    }

    loadMap(): void {
        const map = L.map(this.element);

        L.tileLayer("https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key={accessToken}", {
            attribution:
                '<a href="https://www.maptiler.com/license/maps/" target="_blank">\xA9 MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">\xA9 OpenStreetMap contributors</a>',
            accessToken: "aGf7RlTmiy6UrUpM3r2c",
            crossOrigin: true,
            ...this.optionsValue,
        }).addTo(map);

        const MarkerIcon = L.Icon.extend({
            options: {
                iconSize: [31, 41],
            },
        });

        const icons = {
            animal: new MarkerIcon({ iconUrl: "/static/MapMarker/animal.png" }),
            community: new MarkerIcon({ iconUrl: "/static/MapMarker/community.png" }),
            education: new MarkerIcon({ iconUrl: "/static/MapMarker/education.png" }),
            environment: new MarkerIcon({ iconUrl: "/static/MapMarker/environment.png" }),
            healthcare: new MarkerIcon({ iconUrl: "/static/MapMarker/healthcare.png" }),
            "ngo-support": new MarkerIcon({ iconUrl: "/static/MapMarker/ngo-support.png" }),
            voluntourism: new MarkerIcon({ iconUrl: "/static/MapMarker/voluntourism.png" }),
        };

        if (this.useDatasetValue) {
            const markerClusterGroup = L.markerClusterGroup();

            const mapDatasetElement = document.getElementById("map-dataset");
            if (!mapDatasetElement) {
                console.warn("missing #map-dataset element");
                return;
            }
            const mapDatasetAsJson = mapDatasetElement.textContent;
            if (!mapDatasetAsJson) {
                console.warn("missing dataset content");
                return;
            }
            const mapDataset = JSON.parse(mapDatasetAsJson.trim());

            const programPopupTemplateElement = document.getElementById("map-program-popup") as HTMLTemplateElement;
            if (!programPopupTemplateElement) {
                console.warn("missing template#map-program-popup element");
                return;
            }
            const programPopupTemplate = programPopupTemplateElement.innerHTML;

            mapDataset.Items.forEach((item) => {
                const latLng = L.latLng({ lat: item.Latitude, lng: item.Longitude });
                const marker = L.marker(latLng);
                const icon = icons[item.CategoryIconName];
                if (icon) {
                    marker.setIcon(icon);
                }
                let popupProgramHtml = programPopupTemplate;
                popupProgramHtml = popupProgramHtml.replace("${title}", item.Title);
                popupProgramHtml = popupProgramHtml.replace("${shortDescription}", item.ShortDescription);
                popupProgramHtml = popupProgramHtml.replace("${pricePerWeekText}", item.PricePerWeekText);
                popupProgramHtml = popupProgramHtml.replace("${programPageUrlPath}", item.ProgramPageUrlPath);
                const popup = L.popup().setLatLng(latLng).setContent(popupProgramHtml);
                marker.bindPopup(popup);
                markerClusterGroup.addLayer(marker);
            });

            map.addLayer(markerClusterGroup);
            map.fitBounds(markerClusterGroup.getBounds());
        } else {
            map.setView({ lat: this.latitudeValue, lng: this.longitudeValue }, this.zoomValue);
            L.marker(
                { lat: this.latitudeValue, lng: this.longitudeValue },
                {
                    icon: icons[this.categoryValue.toLowerCase()],
                }
            ).addTo(map);
        }
    }

    setup(): void {
        if (!this.isAppended) {
            this.appendLeafLetScript();
            return;
        }
    }

    intersectionHandler(entries: IntersectionObserverEntry[]): void {
        const entry = entries[0];
        if (entry.isIntersecting) {
            this.setup();
        }
    }

    setupOnClick(event) {
        this.element.innerHTML = "";
        this.setup();
        event.stopPropagation();
    }

    connect(): void {
        if (this.loadingValue === "intersection") {
            this.intersectionObserver = new IntersectionObserver(this.intersectionHandler.bind(this));
            this.intersectionObserver.observe(this.element);
            return;
        } else if (this.loadingValue === "click") {
            // do nothing
            return;
        } else if (this.loadingValue.startsWith("on->")) {
            const eventName = this.loadingValue.replace("on->", "");
            addEventListener(eventName, this.setup.bind(this), { once: true });
            return;
        }

        this.setup();
    }

    disconnect(): void {
        if (this.intersectionObserver) {
            this.intersectionObserver.disconnect();
        }
    }
}
