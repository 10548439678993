import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["sendButton", "fileInput", "fileName", "fileIcon", "addFileBox", "uploadForm"];

    imageTypes = ["image/gif", "image/jpeg", "image/png", "image/tiff"];

    disableSendButton() {
        this.sendButtonTarget.setAttribute("disabled", "");
        this.sendButtonTarget.classList.remove("bg-black");
        this.sendButtonTarget.classList.add("bg-gray-2");
    }

    enableSendButton() {
        if (this.sendButtonTarget.hasAttribute("disabled")) {
            this.sendButtonTarget.removeAttribute("disabled");
            this.sendButtonTarget.classList.remove("bg-gray-2");
            this.sendButtonTarget.classList.add("bg-black");
        }
    }

    showFileSelector() {
        this.fileInputTarget.click();
    }

    resetSelectedFile() {
        this.addFileBoxTarget.removeAttribute("hidden");
        this.uploadFormTarget.setAttribute("hidden", "true");
    }

    isImage(type) {
        return this.imageTypes.includes(type);
    }

    updateFileList() {
        const file = this.fileInputTarget.files[0];
        this.fileNameTarget.textContent = file.name;
        if (this.isImage(file.type)) {
            const img = document.createElement("img");
            img.src = URL.createObjectURL(file);
            img.style.height = "24px";
            img.style.maxWidth = "40px";
            img.style.objectFit = "cover";
            this.fileIconTarget.replaceChildren(img);
        }
        this.uploadFormTarget.removeAttribute("hidden");
        this.addFileBoxTarget.setAttribute("hidden", "");
    }

    confirmDeletingRequest(event) {
        const { documentId } = event.params;
        const yes = confirm(event.currentTarget.getAttribute("data-confirmation-text"));
        if (yes) {
            const secretDeleteLink = document.getElementById(`delete-link-${documentId}`);
            secretDeleteLink.click();
        }
    }
}
