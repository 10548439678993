import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = { targetId: String };

    initialize() {
        this.targetElement = document.getElementById(this.targetIdValue);
    }

    copy(event) {
        this.targetElement.value = event.target.value;
    }
}
