import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    do({ target: element, params }) {
        const targetName = params.targetName;
        if (targetName) {
            const selector = `select[name=${targetName}]`;
            const target = document.querySelector(selector);
            if (!target) {
                console.warn(`${this.identifier}: couldn't find target for ${selector}`);
            }
            if (target.hasAttribute("multiple")) {
                console.warn(`${this.identifier}: select of type "multiple" isn't supported yet`);
            } else {
                target.value = element.selectedOptions[0].value;
                target.dispatchEvent(new Event("change"));
            }
        }
    }
}
