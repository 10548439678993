import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["showMapButtonMobile", "showMapButtonDesktop", "moreInspiration"];

    showMapButtonMobileMobileStatus = "hidden";

    connect() {
        addEventListener("scroll", () => {
            if (window.scrollY > 0 && this.showMapButtonMobileStatus !== "shown") {
                if (this.hasShowMapButtonMobileTarget) {
                    this.showMapButtonMobileTarget.removeAttribute("hidden");
                    this.showMapButtonMobileStatus = "shown";
                }
            } else if (window.scrollY == 0) {
                if (this.hasShowMapButtonMobileTarget) {
                    this.showMapButtonMobileTarget.setAttribute("hidden", "");
                    this.showMapButtonMobileStatus = "hidden";
                }
            }
        });
    }

    moreInspirationTargetConnected(element) {
        this.observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    if (this.hasShowMapButtonMobileTarget) {
                        this.showMapButtonMobileTarget.setAttribute("hidden", "");
                    }
                    if (this.hasShowMapButtonDesktopTarget) {
                        this.showMapButtonDesktopTarget.setAttribute("hidden", "");
                    }
                } else {
                    if (window.scrollY !== 0) {
                        if (this.hasShowMapButtonMobileTarget) {
                            this.showMapButtonMobileTarget.removeAttribute("hidden");
                        }
                        if (this.hasShowMapButtonDesktopTarget) {
                            this.showMapButtonDesktopTarget.removeAttribute("hidden");
                        }
                    }
                }
            },
            {
                rootMargin: "0px",
                threshold: 0,
            }
        );
        this.observer.observe(element);
    }

    showLoadingIndicator() {
        const loadingIndicator = document.getElementById("page-loadingindicator");
        if (loadingIndicator) {
            loadingIndicator.showModal();
        }
    }
}
