import { Controller } from "@hotwired/stimulus";

const MATCH_LETTER = /\p{Letter}/u;
const MATCH_NUMBER = /\d/;

export default class extends Controller {
    static targets = ["isMissingLetter", "isMissingNumber", "isMinCharacters", "input"];

    ariaDescribedBy = "";

    connect() {
        this.ariaDescribedBy = this.inputTarget.getAttribute("aria-describedby");
    }

    markRulesAsFailed(element) {
        const failedIndicatorSelector = `[data-${this.identifier}-rule-indicator=failed]`;
        const failedIndicator = element.querySelector(failedIndicatorSelector);
        failedIndicator.removeAttribute("hidden");
        const passedIndicatorSelector = `[data-${this.identifier}-rule-indicator=passed]`;
        const passedIndicator = element.querySelector(passedIndicatorSelector);
        passedIndicator.setAttribute("hidden", "");
        element.classList.remove("text-green-600");
        element.classList.add("text-red-600");
    }

    markRulesAsPassed(element) {
        const failedIndicatorSelector = `[data-${this.identifier}-rule-indicator=failed]`;
        const failedIndicator = element.querySelector(failedIndicatorSelector);
        failedIndicator.setAttribute("hidden", "");
        const passedIndicatorSelector = `[data-${this.identifier}-rule-indicator=passed]`;
        const passedIndicator = element.querySelector(passedIndicatorSelector);
        passedIndicator.removeAttribute("hidden");
        element.classList.remove("text-red-600");
        element.classList.add("text-green-600");
    }

    do(event) {
        const value = event.target.value;
        let hasFailed = false;

        if (value.match(MATCH_LETTER)) {
            this.markRulesAsPassed(this.isMissingLetterTarget);
        } else {
            hasFailed = true;
            this.markRulesAsFailed(this.isMissingLetterTarget);
        }

        if (value.match(MATCH_NUMBER)) {
            this.markRulesAsPassed(this.isMissingNumberTarget);
        } else {
            hasFailed = true;
            this.markRulesAsFailed(this.isMissingNumberTarget);
        }

        if (value.length >= 8) {
            this.markRulesAsPassed(this.isMinCharactersTarget);
        } else {
            hasFailed = true;
            this.markRulesAsFailed(this.isMinCharactersTarget);
        }

        if (!hasFailed) {
            this.inputTarget.removeAttribute("aria-invalid");
            this.inputTarget.removeAttribute("aria-describedby");
        } else {
            this.inputTarget.setAttribute("aria-invalid", "true");
            if (this.ariaDescribedBy !== "") {
                this.inputTarget.setAttribute("aria-describedby", this.ariaDescribedBy);
            }
        }
    }

    doOnFocusout(event) {
        if (this.inputTarget.value === "") {
            this.inputTarget.removeAttribute("aria-invalid");
            this.inputTarget.removeAttribute("aria-describedby");
            return;
        }
        this.do(event);
    }
}
