import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    avgVolunteerAge = 22;

    connect() {
        if (this.eventHandler) {
            removeEventListener("focus", this.eventHandler);
            removeEventListener("click", this.eventHandler);
        }
        this.eventHandler = () => {
            if (this.element.value === "") {
                var date = new Date();
                date.setFullYear(date.getFullYear() - this.avgVolunteerAge);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, "0");
                const day = date.getDate().toString().padStart(2, "0");
                this.element.value = `${year}-${month}-${day}`;
            }
        };
        this.element.addEventListener("focus", this.eventHandler);
        this.element.addEventListener("click", this.eventHandler);
    }

    disconnect() {
        removeEventListener("focus", this.eventHandler);
        removeEventListener("click", this.eventHandler);
    }
}
