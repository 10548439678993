import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["ignore"];

    static values = {
        onlyIf: String,
    };

    shouldExecute(event, constraintValue) {
        if (!constraintValue) {
            return true;
        }
        const matchCheck = /([^:]+):([^(]+)\(([^)]*)\)/;
        const [_, targetSelector, func, args] = matchCheck.exec(constraintValue);
        if (func === "hasAttribute") {
            let targetElement = event.target;
            if (targetSelector !== "&") {
                targetElement = this.element.querySelector(targetSelector);
            }
            if (targetElement.hasAttribute(args)) {
                return true;
            }
        } else {
            console.warn(`${this.identifier}: unknown function ${func} in check value ${constraintValue}`);
        }
        return false;
    }

    dispatchIfDetected(event) {
        if (!this.shouldExecute(event, this.onlyIfValue)) {
            return;
        }
        if (event.target instanceof Element) {
            let clickInsidedIgnore = false;
            if (this.hasIgnoreTarget) {
                this.ignoreTargets.forEach((element) => {
                    if (element.contains(event.target)) {
                        clickInsidedIgnore = true;
                    }
                });
            }
            if (!this.element.contains(event.target) && !clickInsidedIgnore) {
                this.dispatch("detected");
            }
        }
    }

    activate() {
        setTimeout(() => {
            this.handler = this.dispatchIfDetected.bind(this);
            addEventListener("click", this.handler, { once: true });
        }, 0);
    }
}
