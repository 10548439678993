import { Controller } from "@hotwired/stimulus";

function loadingIndicatorSvg() {
    const womb = document.createElement("div");
    womb.innerHTML = `
    <svg stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><style>.spinner_V8m1{transform-origin:center;animation:spinner_zKoa 1.5s linear infinite}.spinner_V8m1 circle{animation:spinner_YpZS 1.5s ease-in-out infinite}@keyframes spinner_zKoa{100%{transform:rotate(360deg)}}@keyframes spinner_YpZS{0%{stroke-dasharray:0 150;stroke-dashoffset:0}47.5%{stroke-dasharray:42 150;stroke-dashoffset:-16}95%,100%{stroke-dasharray:42 150;stroke-dashoffset:-59}}</style><g class="spinner_V8m1"><circle cx="12" cy="12" r="9.5" fill="none" stroke-width="2"></circle></g></svg>`;
    const svg = womb.firstElementChild;
    svg.classList.add("aspect-square", "text-gray-1", "w-[12%]", "min-w-[64px]", "block");
    return svg;
}

export default class extends Controller {
    static values = {
        id: String,
        options: Object,
        class: String,
        previewImage: String,
        loading: String,
    };

    scriptLoadedEventName = "script-loaded";
    scriptUrl = "https://www.youtube.com/iframe_api";

    createPlayer() {
        const womb = document.createElement("div");
        const videoBox = document.createElement("div");
        womb.appendChild(videoBox);
        womb.classList.add("hidden");
        this.element.appendChild(womb);

        const rect = this.element.getBoundingClientRect();
        this.video = new window.YT.Player(videoBox, {
            videoId: this.idValue,
            width: rect.width,
            height: rect.height,
            playerVars: {
                playsinline: 1,
                modestbranding: 1,
                ...this.optionsValue,
            },
            events: {
                onReady: (event) => {
                    this.zenBox.remove();
                    this.thumbnail.remove();
                    womb.classList.remove("hidden");
                    event.target.playVideo();
                },
            },
        });
    }

    stopVideo() {
        if (!this.video) {
            return;
        }
        this.video.stopVideo();
    }

    appendScript() {
        window.onYouTubeIframeAPIReady = () => {
            this.dispatch(this.scriptLoadedEventName);
        };
        var tag = document.createElement("script");
        tag.src = this.scriptUrl;
        var firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    queryScript() {
        const ee = document.querySelectorAll("head>script");
        for (const e of ee) {
            if (e instanceof HTMLScriptElement) {
                if (e.src === this.scriptUrl) {
                    return e;
                }
            }
        }
        return null;
    }

    isScriptAppended() {
        const e = this.queryScript();
        if (!e) {
            return false;
        }
        return true;
    }

    loadAndPlay() {
        this.youtubeLogoOrLoadingIndicator.replaceWith(loadingIndicatorSvg());
        this.zenBox.classList.add("bg-black");
        if (!this.isScriptAppended()) {
            const event = `${this.identifier}:${this.scriptLoadedEventName}`;
            addEventListener(event, this.createPlayer.bind(this), { once: true });
            this.appendScript();
        } else if (!window.YT) {
            const event = `${this.identifier}:${this.scriptLoadedEventName}`;
            addEventListener(event, this.createPlayer.bind(this), { once: true });
        } else {
            this.createPlayer();
        }
    }

    upgradeThumbnailQuality(src) {
        const x = document.createElement("img");
        x.onload = (event) => {
            if (event.target.width !== 120) {
                // the gray placeholder image was return -> src doesn't exists
                this.thumbnail.onload = undefined;
                this.thumbnail.src = src;
            }
        };
        x.src = src;
    }

    async addThumbnail() {
        this.thumbnail = document.createElement("img");
        const videoId = this.idValue;

        let src = `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;
        if (this.hasPreviewImageValue) {
            src = this.previewImageValue;
        }

        this.thumbnail.onload = this.upgradeThumbnailQuality.bind(
            this,
            `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`
        );
        this.thumbnail.src = src;
        this.thumbnail.alt = "";
        const classes = ["object-cover", "cursor-pointer"];
        if (this.classValue) {
            const videoClasses = this.classValue.split(" ");
            classes.push(...videoClasses);
        }
        this.thumbnail.classList.add(...classes);

        this.zenBox = document.createElement("div");
        this.zenBox.classList.add(
            "absolute",
            "top-0",
            "left-0",
            "w-full",
            "h-full",
            "flex",
            "justify-center",
            "items-center",
            "cursor-pointer"
        );

        this.youtubeLogoOrLoadingIndicator = document.createElement("img");
        this.youtubeLogoOrLoadingIndicator.alt = "";
        this.youtubeLogoOrLoadingIndicator.src =
            "https://image.volunteerworld.com/e04b7ab4fd2b3397b1a11507fd1044b250688661/youtube-icon.svg";
        this.youtubeLogoOrLoadingIndicator.classList.add("w-[12%]", "min-w-[64px]", "block", "cursor-pointer");
        this.zenBox.addEventListener("click", this.loadAndPlay.bind(this), { once: true });
        this.zenBox.appendChild(this.youtubeLogoOrLoadingIndicator);
        this.element.appendChild(this.thumbnail);
        this.element.appendChild(this.zenBox);
    }

    intersectionHandler(entries) {
        const entry = entries[0];
        if (entry.isIntersecting) {
            this.intersectionObserver.disconnect();
            this.addThumbnail();
        }
    }

    async connect() {
        this.element.classList.add("relative");
        if (this.loadingValue === "intersection") {
            this.intersectionObserver = new IntersectionObserver(this.intersectionHandler.bind(this));
            this.intersectionObserver.observe(this.element);
            return;
        } else if (this.loadingValue.startsWith("on->")) {
            const eventName = this.loadingValue.replace("on->", "");
            addEventListener(
                eventName,
                async () => {
                    await this.addThumbnail();
                },
                { once: true }
            );
            return;
        } else {
            await this.addThumbnail();
        }
    }

    disconnect() {
        if (this.intersectionObserver) {
            this.intersectionObserver.disconnect();
        }
    }
}
