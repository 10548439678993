import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["tabs", "data", "projectFocus", "animalsWildlife"];
    static classes = ["active"];

    get tabTargets() {
        return this.tabsTarget;
    }

    connect() {
        this.element[this.identifier] = this;
    }

    changeTab(event) {
        const tabs = Array.from(this.tabsTarget.children);

        tabs.forEach((tab) => {
            tab.classList.remove(this.activeClass);
        });

        event.target.classList.add(this.activeClass);

        const index = tabs.indexOf(event.target);

        const panels = Array.from(this.dataTarget.children);

        panels.forEach((panel) => {
            panel.classList.add("hidden");
        });

        panels[index].classList.remove("hidden");
    }

    changeTabToProjectFocus() {
        const tabs = Array.from(this.tabsTarget.children);

        tabs.forEach((tab) => {
            tab.classList.remove(this.activeClass);
        });

        tabs[0].classList.add(this.activeClass);

        const panels = Array.from(this.dataTarget.children);

        panels.forEach((panel) => {
            panel.classList.add("hidden");
        });

        this.projectFocusTarget.classList.remove("hidden");
    }

    changeTabToAnimalsWildlife() {
        const tabs = Array.from(this.tabsTarget.children);

        tabs.forEach((tab) => {
            tab.classList.remove(this.activeClass);
        });

        tabs[1].classList.add(this.activeClass);

        const panels = Array.from(this.dataTarget.children);

        panels.forEach((panel) => {
            panel.classList.add("hidden");
        });

        this.animalsWildlifeTarget.classList.remove("hidden");
    }
}
