import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["from", "to", "fromOutput", "toOutput"];

    fromTargetConnected(element) {
        element.addEventListener("input", () => {
            const toValue = parseInt(this.toTarget.value, 10);
            const fromValue = parseInt(this.fromTarget.value, 10);
            if (fromValue + 5 >= toValue) {
                this.fromTarget.value = toValue - 5;
            }
            if (this.hasFromOutputTarget) {
                this.fromOutputTarget.value = this.fromTarget.value;
            }
        });
        element.addEventListener("change", (event) => {
            this.dispatch("from-changed", { detail: { value: event.target.value } });
        });
    }

    toTargetConnected(element) {
        element.addEventListener("input", () => {
            const toValue = parseInt(this.toTarget.value, 10);
            const fromValue = parseInt(this.fromTarget.value, 10);
            if (toValue - 5 <= fromValue) {
                this.toTarget.value = fromValue + 5;
            }
            if (this.hasToOutputTarget) {
                if (this.toOutputTarget.hasAttribute(`data-${this.identifier}-plus-if-max`)) {
                    const maxValue = parseInt(this.toTarget.getAttribute("max"), 10);
                    if (toValue === maxValue) {
                        this.toOutputTarget.value = `${this.toTarget.value}+`;
                    } else {
                        this.toOutputTarget.value = this.toTarget.value;
                    }
                } else {
                    this.toOutputTarget.value = this.toTarget.value;
                }
            }
        });
        element.addEventListener("change", (event) => {
            this.dispatch("to-changed", { detail: { value: event.target.value } });
        });
    }
}
