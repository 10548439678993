import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [
        "fakeTextarea",
        "textarea",
        "sendButton",
        "messagesContainer",
        "fileInput",
        "fileInfo",
        "fileName",
        "fileIcon",
        "lastMessageElement",
    ];

    imageTypes = ["image/gif", "image/jpeg", "image/png", "image/tiff"];

    sendButtonEnabled = false;

    disableSendButton() {
        this.sendButtonEnabled = false;
        this.sendButtonTarget.setAttribute("disabled", "");
        this.sendButtonTarget.classList.remove("bg-black");
        this.sendButtonTarget.classList.add("bg-gray-2");
    }

    connect() {
        this.textareaStartHeight = this.textareaTarget.getBoundingClientRect().height;
    }

    enableSendButton() {
        if (!this.sendButtonEnabled) {
            this.sendButtonEnabled = true;
            this.sendButtonTarget.removeAttribute("disabled");
            this.sendButtonTarget.classList.remove("bg-gray-2");
            this.sendButtonTarget.classList.add("bg-black");
        }
    }

    adjustHeight() {
        const text = this.textareaTarget.value.trim();
        if (text === "") {
            requestAnimationFrame(() => {
                this.disableSendButton();
                this.textareaTarget.style.height = `${this.textareaStartHeight}px`;
            });
        } else if (text !== "") {
            this.enableSendButton();
        }
        const scrollHeight = this.textareaTarget.scrollHeight;
        if (scrollHeight >= 2 * this.textareaStartHeight) {
            // workaround for textarea start height, keeps single line height until there is more the on line necessary
            this.textareaTarget.style.height = "auto";
            this.textareaTarget.style.height = `${scrollHeight}px`;
        }
    }

    showFileSelector() {
        this.fileInputTarget.click();
    }

    leaveTextBox() {
        const text = this.textareaTarget.value.trim();
        if (text === "") {
            this.disableSendButton();
        }
    }

    isImage(type) {
        return this.imageTypes.includes(type);
    }

    updateFileList() {
        const file = this.fileInputTarget.files[0];
        this.fileNameTarget.textContent = file.name;
        if (this.isImage(file.type)) {
            const img = document.createElement("img");
            img.src = URL.createObjectURL(file);
            img.style.height = "24px";
            img.style.maxWidth = "40px";
            img.style.objectFit = "cover";
            this.fileIconTarget.replaceChildren(img);
        }
        this.fileInfoTarget.removeAttribute("hidden");
    }

    lastMessageElementTargetConnected(element) {
        element.scrollIntoView({ block: "center" });
    }
}
