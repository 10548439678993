import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    showHide(event) {
        const { targetId } = event.params;
        const targetSelector = `[data-${this.identifier}-id=${targetId}]`;
        const targets = document.querySelectorAll(targetSelector);
        for (const target of targets) {
            if (target.hasAttribute("hidden")) {
                target.removeAttribute("hidden");
            } else {
                target.setAttribute("hidden", "");
            }
        }
    }
}
