import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        notAfterSec: Number,
    };
    declare readonly notAfterSecValue: number;
    declare readonly hasNotAfterSecValue: boolean;

    static classes = ["not", "yes"];
    declare readonly notClass: string;
    declare readonly yesClass: string;
    declare readonly hasNotClass: string;
    declare readonly hasYesClass: string;

    show(): void {
        if (this.hasYesClass) {
            this.element.classList.add(this.yesClass);
        }
        if (this.hasNotClass) {
            this.element.classList.remove(this.notClass);
        }
        if (!this.hasYesClass && !this.hasNotClass) {
            this.element.removeAttribute("hidden");
        }

        if (this.hasNotAfterSecValue) {
            setTimeout(() => {
                if (this.hasYesClass) {
                    this.element.classList.remove(this.yesClass);
                }
                if (this.hasNotClass) {
                    this.element.classList.add(this.notClass);
                }
                if (!this.hasYesClass && !this.hasNotClass) {
                    this.element.setAttribute("hidden", "");
                }
            }, this.notAfterSecValue * 1000);
        }
    }
}
